import React,{useState,useEffect} from 'react'
import Modal from 'react-bootstrap/Modal';
import Authuser from '../Authuser';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
function Edituser(props) {
  const { http,getUser } = Authuser();
  let id = props.id;
  let userdetails = getUser();
  const [values,setValues]=useState({firstname:'',lastname:'',email:'',designation:'',phonenumber:''});
  const [errors, setErrors] = useState({firstname:'',lastname:'',email:'',designation:'',phonenumber:'',message:''});
  const [message,setMessage]= useState();
  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const alphaRegex = /^[A-Za-z]+$/;
    const numRegex = /^[0-9\b]+$/;
    const phoneNumberRegex = /^\d{10}$/;

    // First Name
    if (!values.firstname.trim()) {
        newErrors.firstname = 'First name is required';
        isValid = false;
    }else if(!alphaRegex.test(values.firstname)){
        newErrors.firstname='Only Characters allowed';
        isValid = false;
    }else if(values.firstname.length < 3){
        newErrors.firstname='First name should be atleast 3 characters';
        isValid = false;
    }else {
        newErrors.firstname = '';
    }
    // Last Name
    if (!values.lastname.trim()) {
        newErrors.lastname = 'Last name is required';
        isValid = false;
    } else if(!alphaRegex.test(values.lastname)){
        newErrors.lastname='Only Characters allowed';
        isValid = false;
    }else if(values.lastname.length < 3){
      newErrors.lastname='Last name should be atleast 3 characters';
      isValid = false;
    }else {
        newErrors.lastname = '';
    }
    // Designation
    if (!values.designation.trim()) {
        newErrors.designation = 'Designation is required';
        isValid = false;
    } else if(!alphaRegex.test(values.designation)){
        newErrors.designation='Only Characters allowed';
        isValid = false;
    }else {
        newErrors.designation = '';
    }
    // Subject
    if (!values.phonenumber.trim()) {
        newErrors.phonenumber = 'Phone is required';
        isValid = false;
    } else if(!numRegex.test(values.phonenumber)){
        newErrors.phonenumber='Only numbers allowed';
        isValid = false;
    }else if(!phoneNumberRegex.test(values.phonenumber)){
      newErrors.phonenumber='Phone number should be 10 digits';
      isValid = false;
    }else {
        newErrors.phonenumber = '';
    }
    setErrors(newErrors);
    setMessage('');
    return isValid;
};
function handleChange(e){
    setMessage('');
    setValues({...values,[e.target.name]:e.target.value});
}
const handleSubmit = async(e) =>{
    e.preventDefault();
    let firstname = values.firstname;
    let lastname = values.lastname;
    let email = e.target.email.value;
    let phonenumber = values.phonenumber;
    let designation = values.designation;
    let userid = id;
    let message = '';
    const newErrors = { ...errors };
    
    if (validateForm()) {
      http.post(`hr/updateuser/${userdetails.email}`,{firstname:firstname,lastname:lastname,email:email,phonenumber:phonenumber,designation:designation,id:props.id,userid:userdetails.id}).then((response)=>{
          
          
          e.target.reset();
          setMessage('User created successfully!');
          
      }).catch(function (error) {
        // handle error
        //console.log(error.response.data.error);
        if(error.response.status==403){
            newErrors.firstname = error.response.data?.error?.firstname;
            newErrors.lastname = error.response.data?.error?.lastname;
            newErrors.phonenumber = error.response.data?.error?.phonenumber;
            newErrors.designation = error.response.data?.error?.designation;
            setErrors(newErrors);
        }
        if(error.response.status==401){
            newErrors.message = error.response.data?.error;
            setErrors(newErrors);
        }
        //setsubmitted(false)
      })
    } else {
      // Form is not valid, display error messages

    }
}
//Fetch Data of user
useEffect(()=>{
  if(props.id != ''){
    http.get(`/hr/showuser/${userdetails.email}?id=${props.id}`)
    .then((response) => {
      //console.log(response.data);
      setValues(response.data.showuser);
    })
    .catch(function (error) {
    // handle error
    console.log(error.response.data.error);
    });
  }
  
},[props.id])
  return (
    <>
      <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={props.show} onHide={props.close} centered>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form method="post" onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" placeholder="Enter First name" value={values?.firstname} name="firstname" onChange={handleChange} />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.firstname}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Last name" value={values?.lastname} name="lastname" onChange={handleChange} />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.lastname}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Designation</Form.Label>
              <Form.Control type="text" placeholder="Enter Designation" value={values?.designation} name="designation" onChange={handleChange} />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.designation}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
              <Form.Label>Email</Form.Label>
              <Form.Control type="text" placeholder="Enter Email" value={values?.email} name="email" readonly />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.email}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control type="text" placeholder="Enter Phone Number" value={values?.phonenumber} maxLength={10} name="phonenumber" onChange={handleChange}/>
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.phonenumber}</div>
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.message}</div>
              <div style={{display:'block', color: "#1cc88a" }} className="alert-success success small">{message}</div> 
            </Form.Group>
            <div className='text-center mt-2'>
              <Button variant="primary" type="submit" onClick={validateForm} className="rounded-0 text-white" style={{backgroundColor:'#2F9DCC'}}>Submit</Button>
            </div>
          </Form>
        </Modal.Body>
      <Modal.Footer></Modal.Footer>
  </Modal>
    </>
  )
}

export default Edituser
import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import UserActions from "../Components/UserActions";
import { useState,useEffect,useMemo } from "react";
import Authuser from '../Authuser';
function ManageUsers(){
    const{http,getUser}=Authuser();
    let userdetails = getUser();
    // Get Users API
    const fetchItems = async () => {
        http.get(`hr/manageusers/${userdetails.email}`)
        .then((response) => {
            //console.log(response.data);
            setRowData(response.data.hrusers);
        })
        .catch(function (error) {
        // handle error
        console.log(error.response.data.error);
        });
    };
    const refreshData = () => {
        fetchItems(); // Re-fetch data after delete
      };
    useEffect(() => {
        fetchItems();
    }, []);
    const frameworkComponents = {
        UserActionRenderer: (parms) => <UserActions {...parms} refreshData={refreshData} />,
     };
    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState([]);
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        { headerName: "FirstName",field:"firstname"},
        { field: "",
            cellRenderer: UserActions,
            cellRendererParams: {
                refreshData: refreshData // Pass folder name or other data as custom params
            },
            cellStyle: { overflow: "visible", zIndex: "auto" },// Set this line
            resizable: true,
            suppressMenu: true,
            minWidth: 100,
            width: 80
        }, 
        { headerName: "LastName",field:"lastname"},
        { headerName: "Designation",field:"designation"},
        { headerName: "Email",field:"email"},
        { headerName: "PhoneNumber",field:"phonenumber"},
    ]);   
    // ...
    
    
    
    const defaultColDef = useMemo(() => {
        return {
            flex: 1, 
            filter: true,
        
            
        }
    });
    
    
   return (
        <Container fluid>
             <Breadcrumb className='mt-2'>
                <Breadcrumb.Item as={Link} to="/">Dashboard</Breadcrumb.Item>
                 <Breadcrumb.Item active>  Manage Users</Breadcrumb.Item>
               
            </Breadcrumb>
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6>  Manage Users</h6>
                </Col>
                <Col sm={4} className='pb-2'>
                </Col>
                <Col sm={4} className="d-flex justify-content-end">
              
                </Col>
                <div className="ag-theme-quartz" // applying the grid theme
                style={{ height: 550 }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}
                        frameworkComponents={frameworkComponents}
                    />
                    </div>
           </Row>
 </Container>

    );
}

export default ManageUsers
import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Authuser from '../Authuser';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

import { useState,useMemo } from "react";


function Timesheets(){

    const now = new Date();
    const current = now.getFullYear();
    const previous = current - 1;
    const [values,setValues]=useState({type:'',projecttitle:'',month:'',year:'',workhrs:'',document:''});
    const [errors, setErrors] = useState({type:'',projecttitle:'',month:'',year:'',workhrs:'',document:''});
    const [message,setMessage]= useState();
    const [smShow, setSmShow] = useState(false);
    const { http, getUser } = Authuser();
    let userdetails = getUser();
    const [months,setMonths]=useState(
        {
            'Jan': "01",'Feb': "02",'Mar': "03",'Apr': "04",'May': "05",'Jun': "06",'Jul': "07",'Aug': "08",'Sep': "09",'Oct': "10",'Nov': "11",'Dec': "12",
            'Q1 Jan - Mar' : '01,02,03','Q2 Apr - Jun' : '04,05,06','Q3 Jul - Sep' : '07,08,09','Q4 Oct - Dec' : '10,11,12','Year to Date' : current,
        });
    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState([
                { MonthYear: "Jan",  Quarterly: "Q1 Jan - Mar", HistoricalData: "2022", },
                { MonthYear: "Feb",  Quarterly: "Q2 Apr - Jun", HistoricalData: "2023", },
                { MonthYear: "Mar",  Quarterly: "Q3 Jul - Sep", HistoricalData: "2024", },
                { MonthYear: "Apr",  Quarterly: "Q4 Oct - Dec", HistoricalData: "", },
                { MonthYear: "May",  Quarterly: "Year to Date", HistoricalData: "", },
                { MonthYear: "Jun",  Quarterly: "", HistoricalData: "", },
                { MonthYear: "Jul",  Quarterly: "", HistoricalData: "", },
                { MonthYear: "Aug",  Quarterly: "", HistoricalData: "", },
                { MonthYear: "Sep",  Quarterly: "", HistoricalData: "", },
                { MonthYear: "Oct",  Quarterly: "", HistoricalData: "", },
                { MonthYear: "Nov",  Quarterly: "", HistoricalData: "", },
                { MonthYear: "Dec",  Quarterly: "", HistoricalData: "", },
            ]);
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        { field: "MonthYear",
            cellRenderer: (params) => {
            return (
            <a href={`/Timesheetsbyyear/${months[params.value]}`} >
                {params.value} {current}
            </a>
            );
        }}, 
        
        { field: "Quarterly", cellRenderer: (params) => {
            return (
            <a href={`/Timesheetsbyyear/${months[params.value]}`} >
                {params.value}
            </a>
            );
        }}, 
        { field: "HistoricalData",cellRenderer: (params) => {
            return (
            <a href={`/Timesheetsbyyear/${params.value}`} >
                {params.value}
            </a>
            );
        } },
        ]);
    // ...
    const defaultColDef = useMemo(() => {
        return {
        flex: 1, 
        filter: true,
        
        
        }
    });
   return (
        <Container fluid>
             <Breadcrumb className='mt-2'>
                <Breadcrumb.Item as={Link} to="/">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active> Hr </Breadcrumb.Item>
                <Breadcrumb.Item active>  Timesheets</Breadcrumb.Item>
               
            </Breadcrumb>
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6> Timesheets</h6>
                </Col>
                <Col sm={4} className='pb-2'>
                </Col>
                <Col sm={4} className="d-flex justify-content-end">
               
                </Col>
                <div className="ag-theme-quartz" // applying the grid theme
                style={{ height: 550 }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        defaultColDef={defaultColDef}
                       
                    />
                    </div>
           </Row>

           
 </Container>

    );
}

export default Timesheets
import React,{useState} from 'react'
import pdf from '../Assets/pdf_ico.png';
import Doc from '../Assets/doc.png'
import Authuser from '../Authuser';
import ViewDocument from './ViewDocument';
function Pdf(props) {
  const { apiurl } = Authuser();
  const{data,folderName}=props;
  const [modalshow, setModalshow] = useState(false);
  const [file, setFile] = useState(false);
    const handleModalShow = (filename) =>{
        console.log(filename)
        setModalshow(true);
        setFile(filename);
    }
  var filesrc = String(data.file_name).split('.').pop() == 'pdf' ? pdf : Doc;

  return (
    <>
      <a href="#" onClick={() => handleModalShow(data.file_name)}><img src={filesrc} alt='Profile' className='img-fluid' style={{width:20}} /></a>
      <ViewDocument show={modalshow} filename={file} foldertype={folderName} close={() => setModalshow(false)}/>
    </>
  )
}

export default Pdf
import { Routes,Route } from 'react-router-dom';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import Authuser from './Authuser';
import Login from './Pages/Login';
import ForgotPassword from './Pages/ForgotPassword';
import Footer from './Components/Footer';
import Homenav from './Navbar/Homenav';
import Resetpassword from './Pages/Resetpassword';
function App() {
  const {getToken} = Authuser();
  if(!getToken()){
    return(
      <div>
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="/ForgotPassword" exact element={<ForgotPassword />} />
          <Route path="/resetpassword/:token/:email" element={<Resetpassword pageview="Reset Password" apiurl="passwordUpdate"/>} />
          <Route path="*" exact element={<Login />} />
        </Routes>
      </div>
    )
  }
  return (
    <>
      <Homenav />
      <Footer />
    </>
  );
}

export default App;

import React,{useState} from 'react'
import {
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";
import ViewDocument from "./ViewDocument";
import Authuser from "../Authuser";
function ViewDownloadDeleteRenderer(props) {
  const{http,getUser} = Authuser();
  let userdetails = getUser();
  const { folderName, data, columnname } = props;
  let viewfile = '';
  const [modalshow, setModalshow] = useState(false);
  const [file, setFile] = useState(false);
  const handleModalShow = (filename) =>{
      console.log(filename)
      setModalshow(true);
      setFile(filename);
  }
  //Download a file
  const handleDownload = (filename) => {
        
    http.post(`/hr/downloadFiles`,{files:filename,folderName:folderName}, {
        responseType: 'blob',
    })
    .then((response) => {
        //console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url; //''
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
    .catch(function (error) {
        // handle error
        console.log(error.response.data.error);
    });
    
  };
  //Delete a file
  const handleDelete = (id) => {
    http.post(`/hr/deleteFile/${userdetails.email}`,{id:id,folderName:folderName})
    .then((response) => {
        //console.log(response);
        props.refreshData();
    })
    .catch(function (error) {
        // handle error
        console.log(error.response.data.error);
    });
  }
  return (
    <>
      <DropdownButton  as={ButtonGroup} size="sm" autoClose="outside" variant="muted" style={{ maxHeight: "28px" }} title={<span><i class="bi bi-three-dots"></i></span>}>
        <Dropdown.Item href="#" onClick={() => handleModalShow(data.essdocument)}> View</Dropdown.Item>
        <Dropdown.Item onClick={() => handleDownload(data.essdocument)}  href="#">Download</Dropdown.Item>
        <Dropdown.Item onClick={() => handleDelete(data.id)}  href="#">Delete</Dropdown.Item>
      </DropdownButton>
      <ViewDocument show={modalshow} filename={file} foldertype={folderName} close={() => setModalshow(false)}/>
    </>
    

  )
}

export default ViewDownloadDeleteRenderer
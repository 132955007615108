import React,{useState,useEffect,useMemo} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Authuser from '../Authuser';

function   AllEmployees () {
  const {http,getUser} = Authuser();
  let userdetails = getUser();
  const [values,setValues]=useState({firstname:'',lastname:'',email:'',phone:'',designation:'',country:'USA',state:'',gender:'',education:'',workexperience:'',dateofjoin:'',employeeid:'',certifications:'',addressline1:'',addressline2:'',zipcode:''});
  const [errors, setErrors] = useState({firstname:'',lastname:'',email:'',phone:'',designation:'',country:'',state:'',gender:'',education:'',workexperience:'',dateofjoin:'',employeeid:'',certifications:'',addressline1:'',addressline2:'',zipcode:''});
  const [smShow, setSmShow] = useState(false);
  const [message,setMessage]= useState();
  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([]);
  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([      
    { headerName:'First Name',field: "firstname"},    
    { headerName:'Last Name',field: "lastname" },
    { headerName:'Designation',field: "designation"},
    { headerName:'Email',field: "email" },      
    { headerName:'Phone',field: "phone" },
    { headerName:'Gender',field: "gender" },
    { headerName:'Education',field: "education" },  
    { headerName:'Work Exp',field: "workexperience" },  
    { headerName:'DateOfJoining',field: "dateofjoin" }, 
    { headerName:'EmployeeID',field: "employeeid" }, 
    { headerName:'Certifications',field: "certifications" },
    { headerName:'Address',field: "addressline1" },
    { headerName:'Date and time',field: "created_at" },
  ]);   
  // ...
  const defaultColDef = useMemo(() => {
    return {
        flex: 1,
        filter: true,       
    }
  });
  // Employee List function
  const fetchItems = async () => {
      http.get(`hr/employeelist/${userdetails.email}`)
      .then((response) => {
      console.log(response.data);
      setRowData(response.data.employees);
      })
      .catch(function (error) {
      // handle error
      console.log(error.response.data.error);
      });
  };
  useEffect(() => {
      fetchItems();
  }, []);
  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const alphaRegex = /^[A-Za-z]+$/;
    const numRegex = /^[0-9\b]+$/;
    // First Name
    if (!values.firstname.trim()) {
        newErrors.firstname = 'First name is required';
        isValid = false;
    }else if(!alphaRegex.test(values.firstname)){
        newErrors.firstname='Only Characters allowed';
        isValid = false;
    }else if(values.firstname.length < 3){
      newErrors.firstname='Atleast 3 Characters allowed';
      isValid = false;
    }else{
        newErrors.firstname = '';
    }
    // eventdate
    if (!values.lastname.trim()) {
        newErrors.lastname = 'Last name is required';
        isValid = false;
    } else if(!alphaRegex.test(values.lastname)){
        newErrors.lastname='Only Characters allowed';
        isValid = false;
    }else if(values.lastname.length < 3){
      newErrors.lastname='Atleast 3 Characters allowed';
      isValid = false;
    }else {
        newErrors.lastname = '';
    }
    // Designation
    if (!values.designation.trim()) {
      newErrors.designation = 'Designation is required';
      isValid = false;
    } else if(!alphaRegex.test(values.designation)){
        newErrors.designation='Only Characters allowed';
        isValid = false;
    }else {
        newErrors.designation = '';
    }
    //Email
    if (!values.email.trim()) {
        newErrors.email = 'Email is required';
        isValid = false;
    } else  if(!emailRegex.test(values.email)){
        newErrors.email = 'Email not valid';
        isValid = false;
    }else {
        newErrors.email = '';
    }
    if (!values.phone.trim()) {
        newErrors.phone = 'Phone is required';
        isValid = false;
    } else if(!numRegex.test(values.phone)){
        newErrors.phone='Only numbers allowed';
        isValid = false;
    }else {
        newErrors.phone = '';
    }
    if (!values.country.trim()) {
        newErrors.country = 'Country is required';
        isValid = false;
    } else {
        newErrors.country = '';
    }
    // Subject
    if (!values.state.trim()) {
        newErrors.state = 'State is required';
        isValid = false;
    }else if(!alphaRegex.test(values.state)){
      newErrors.state='Only Characters allowed';
      isValid = false;
    } else {
        newErrors.state = '';
    }
    // eventdate
    if (!values.gender.trim()) {
        newErrors.gender = 'Gender is required';
        isValid = false;
    }else if(!alphaRegex.test(values.gender)){
      newErrors.gender='Only Characters allowed';
      isValid = false;
    } else {
        newErrors.gender = '';
    }
    // File Upload
    if (!values.education.trim()) {
        newErrors.education = 'Education is required';
        isValid = false;
    } else {
        newErrors.education = '';
    }
    // Subject
    if (!values.workexperience.trim()) {
      newErrors.workexperience = 'Work Experience is required';
      isValid = false;
    } else {
      newErrors.workexperience = '';
    }
    // eventdate
    if (!values.dateofjoin.trim()) {
      newErrors.dateofjoin = 'Date of join is required';
      isValid = false;
    } else {
      newErrors.dateofjoin = '';
    }
    if (!values.workexperience.trim()) {
      newErrors.workexperience = 'Work Experience is required';
      isValid = false;
    } else {
      newErrors.workexperience = '';
    }
    if (!values.employeeid.trim()) {
      newErrors.employeeid = 'Employee id is required';
      isValid = false;
    } else {
      newErrors.employeeid = '';
    }
    if (!values.certifications.trim()) {
      newErrors.certifications = 'Certifications is required';
      isValid = false;
    } else {
      newErrors.certifications = '';
    }
    if (!values.addressline1.trim()) {
      newErrors.addressline1 = 'Address Line 1 is required';
      isValid = false;
    } else {
      newErrors.addressline1 = '';
    }
    if (!values.addressline2.trim()) {
      newErrors.addressline2 = 'Address Line 2 is required';
      isValid = false;
    } else {
      newErrors.addressline2 = '';
    }
    if (!values.zipcode.trim()) {
      newErrors.zipcode = 'Zipcode is required';
      isValid = false;
    } else {
      newErrors.zipcode = '';
    }
    
    setErrors(newErrors);
    setMessage('');
    return isValid;
};
function handleChange(e){
    setMessage('');
    setValues({...values,[e.target.name]:e.target.value});
}
const handleSubmit = async(e) =>{
    e.preventDefault();
    let firstname = values.firstname;
    let lastname = values.lastname;
    let email = values.email;
    let phone = values.phone;
    let designation = values.designation;
    let country = values.country;
    let state = values.state;
    let gender = values.gender;
    let education = values.education;
    let workexperience = values.workexperience;
    let dateofjoin = values.dateofjoin;
    let employeeid = values.employeeid;
    let certifications = values.certifications;
    let addressline1 = values.addressline1;
    let addressline2 = values.addressline2;
    let zipcode = values.zipcode;
    let message = '';
    const newErrors = { ...errors };
    
    if (validateForm()) {
      http.post(`hr/addemployee/${userdetails.email}`,{firstname:firstname,lastname:lastname,email:email,phone:phone,designation:designation,country:'USA',state:state,gender:gender,education:education,workexperience:workexperience,dateofjoin:dateofjoin,employeeid:employeeid,certifications:certifications,addressline1:addressline1,addressline2:addressline2,zipcode:zipcode,userid:userdetails.id}).then((response)=>{
          //setToken(response.data.userdetail,response.data.token,usertype);
          //console.log(response.status);
          console.log(response.data.message);
          setValues({
            firstname: '',
            lastname: '',
            email: '',
          });
          e.target.reset();
          setMessage('Employee added successfully!');
          fetchItems();
          
      }).catch(function (error) {
        // handle error
        console.log(error.response.data.error);
        if(error.response.status==403){
            newErrors.firstname = error.response.data?.error?.firstname;
            newErrors.lastname = error.response.data?.error?.lastname;
            newErrors.email = error.response.data?.error?.email;
            newErrors.phone = error.response.data?.error?.phone;
            setErrors(newErrors);
        }
        if(error.response.status==401){
            newErrors.message = error.response.data?.error;
            setErrors(newErrors);
        }
        //setsubmitted(false)
      })
    } else {
      // Form is not valid, display error messages

    }
}
  return(
    <Container fluid>
      <Breadcrumb className='mt-2'>
        <Breadcrumb.Item as={Link} to="/">Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item active> ALL EMPLOYEES</Breadcrumb.Item>               
      </Breadcrumb>
      <Row className='m-1'>
        <Col sm={4} className='d-flex'><h6>ALL EMPLOYEES</h6></Col>
        <Col sm={4} className='pb-2'></Col>
        <Col sm={4} className="d-flex justify-content-end">
          <Button variant="primary" className='rounded-0 ml-2 shadow-sm' onClick={() => setSmShow(true)}><i class="bi bi-plus"></i> Add Employee</Button>
        </Col>
        <div className="ag-theme-quartz" // applying the grid theme
          style={{ height: 550 }} // the grid will fill the size of the parent container
          >
              <AgGridReact
                  rowData={rowData}
                  columnDefs={colDefs}
                  pagination={true}
                  paginationPageSize={10}
                  paginationPageSizeSelector={[10, 20]}
                  defaultColDef={defaultColDef}
                  
              />
        </div>
      </Row>
      <Modal size="md" show={smShow} onHide={() => setSmShow(false)} aria-labelledby="example-modal-sizes-title-sm">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" className='fw-bold'>
            Add Employee
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form method="post" onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>First Name *</Form.Label>
              <Form.Control type="text" name="firstname" onChange={handleChange} placeholder="Enter First Name" />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.firstname}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>Last Name *</Form.Label>
              <Form.Control type="text" name="lastname" onChange={handleChange} placeholder="Enter Last Name" />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.lastname}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>Designation *</Form.Label>
              <Form.Control type="text" name="designation" onChange={handleChange} placeholder="Enter Designation" />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.designation}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>Email *</Form.Label>
              <Form.Control type="email" name="email" onChange={handleChange} placeholder="Enter Email" />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.email}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>Phone *</Form.Label>
              <Form.Control type="text" name="phone" maxLength={10} onChange={handleChange} placeholder="Enter Phone Number" />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.phone}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>Date of Birth *</Form.Label>
              <Form.Control type="date" name="dateofbirth" onChange={handleChange} placeholder="" />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.dateofbirth}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>Gender*</Form.Label>
              <Form.Control type="text" name="gender" onChange={handleChange} placeholder="" />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.gender}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>Education *</Form.Label>
              <Form.Control type="text" name="education" onChange={handleChange} placeholder="Enter Education" />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.education}</div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>Role / Designation *</Form.Label>
              <Form.Control type="text" name="designation" onChange={handleChange} placeholder="Enter Role Designation" />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.designation}</div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>Work Experience *</Form.Label>
              <Form.Control type="text" name="workexperience" onChange={handleChange} placeholder="Enter Work Experience " />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.workexperience}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>Date of Joining *</Form.Label>
              <Form.Control type="date" name="dateofjoin" onChange={handleChange} placeholder="Enter Date of Joining " />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.dateofjoin}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>Employee ID *</Form.Label>
              <Form.Control type="text" name="employeeid" onChange={handleChange} placeholder="Enter Employee ID " />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.employeeid}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>Certifications *</Form.Label>
              <Form.Control type="text" name="certifications" onChange={handleChange} placeholder="Enter Certifications " />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.certifications}</div>
            </Form.Group>
            
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>Address Line 1 *</Form.Label>
              <Form.Control type="text" name="addressline1" onChange={handleChange} placeholder="Enter Address Line one" />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.addressline1}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>Address Line 2 *</Form.Label>
              <Form.Control type="text" name="addressline2" onChange={handleChange} placeholder="Enter Address Line two" />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.addressline2}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>Country *</Form.Label>
              <Form.Control type="text" name="country" onChange={handleChange} placeholder="USA" />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.country}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>State *</Form.Label>
              <Form.Control type="text" name="state" onChange={handleChange} placeholder="Enter State" />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.state}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>Zip Code *</Form.Label>
              <Form.Control type="text" name="zipcode" onChange={handleChange} placeholder="Enter Zip Code" />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.zipcode}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>Choose Password * </Form.Label>
              <Form.Control type="text" name="password" onChange={handleChange} placeholder="Choose Password" />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.password}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className='fw-bold'>Reconfirm Password * </Form.Label>
              <Form.Control type="text" name="confirmpassword" onChange={handleChange} placeholder="Reconfirm Password" />
              <div style={{ display: "block" }} className="invalid-feedback small">{errors.confirmpassword}</div>
            </Form.Group>
            <div style={{ display: "block" }} className="alert-success success small">{message}</div>
            <div className='text-center'>
              <Button variant="primary" type="submit" onClick={validateForm} className='rounded-0'>
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal> 
    </Container>

    );
}

export default  AllEmployees
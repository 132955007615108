import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { useState,useMemo } from "react";
import Header from '../Components/Header';
import Footer from '../Components/Footer';

function Holidays(){
    const [smShow, setSmShow] = useState(false);
    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState([
        { Day: "Monday", DateAndTime: "Jan 01, 2024",Holiday: "New Year's Day", },
        { Day: "Wednesday", DateAndTime: "May 29, 2024",Holiday: "Memorial Day", },
        { Day: "Thursday	", DateAndTime: "Jul 04, 2024",Holiday: "Independence Day", },
        { Day: "Wednesday", DateAndTime: "May 29, 2024",Holiday: "Labor Day", },
        { Day: "Monday", DateAndTime: "Nov 11, 2024",Holiday: "Veterans Day", },
        { Day: "Sunday", DateAndTime: "Nov 24, 2024",Holiday: "Thanksgiving Day", },
        { Day: "Wednesday", DateAndTime: "Dec 25, 2024",Holiday: "Christmas Day", },
    ]);
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
    
        { field: "Day"},    
        { field: "DateAndTime" },
        { field: "Holiday" },    
    ]);    
    // ...
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            filter: true,           
        }
    });
   return (
        <Container fluid>
            <Breadcrumb className='mt-2'>
                <Breadcrumb.Item as={Link} to="/">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active> Holidays</Breadcrumb.Item>               
            </Breadcrumb>
            <Row className='m-1'>
                <Col sm={4} className='d-flex'><h6>  Holidays</h6></Col>
                <Col sm={4} className='pb-2'></Col>
                <Col sm={4} className="d-flex justify-content-end"></Col>
                <div className="ag-theme-quartz m-auto" // applying the grid theme
                style={{ height: 550, width:600, }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}
                       
                    />
                </div>
           </Row>
        </Container>
    );
}

export default Holidays
import React,{ useState,useEffect,useMemo }  from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Pdf from "../Components/Pdf";
import ViewDownload from "../Components/ViewDownload";
import Button from 'react-bootstrap/Button';
import Authuser from '../Authuser';
import { useParams } from 'react-router-dom';
function TimesheetsByYear() {
    const now = new Date();
    const current = now.getFullYear();
    const previous = current - 1;
    const [months,setMonths]=useState(
    {
        '01': "Jan",'02': "Feb",'03': "Mar",'04': "Apr",'05': "May",'06': "Jun",'07': "Jul",'08': "Aug",'09': "Sep",'10': "Oct",'11': "Nov",'12': "Dec",
        '01,02,03': 'Q1 Jan - Mar','04,05,06' : 'Q2 Apr - Jun','07,08,09' : 'Q3 Jul - Sep','10,11,12' : 'Q4 Oct - Dec'
    });
    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState([]);
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        { headerName:"EmployeeName",field: "EmployeeName",
            cellRenderer:(params) =>{
                return(
                    <span>{params.data.firstname} {params.data.lastname}</span>
                )
            }
        }, 
        { headerName:"Status",field: "status"},
        { headerName:"Month",field: "month"},
        { headerName:"Year",field: "year"}, 
        { headerName:"Working Hours",field: "workhrs"},
        { headerName:"Attachment",field: "file_name",
            cellRenderer: Pdf,
            cellRendererParams: {
                folderName: 'empselftimesheets', // Pass folder name or other data as custom params\
                //fetchItems: fetchItems
            }
        }, 
        { headerName: "Submitted DateAndTime",field:'created_at'  },
        { field: "",
            cellRenderer: ViewDownload,
            cellRendererParams: {
                folderName: 'empselftimesheets', // Pass folder name or other data as custom params
            },
        },
    ]);
    // ...
    const defaultColDef = useMemo(() => {
        return {
            flex: 1, 
            filter: true,
        }
    });
    const {month} = useParams();
    const { http, getUser } = Authuser();
    let userdetails = getUser();
    console.log(month)
    const [file, setFile] = useState(false);
    const [show, setShow] = useState(false);
    const [timesheetreports,setTimesheetreports]=useState([]);
    const [reportof,setReportof]=useState('');
    const [year,setYear]=useState('');
    const [modalshow, setModalshow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedid, setSelectedid] = useState(null);
    function fetchItems(){
        if(month == current || month == previous){           
            var url = `/hr/timesheetreport/${userdetails.email}?year=${month}`;
        }else{
            var url = `/hr/timesheetreport/${userdetails.email}?id=${month}`;
        }
        http.get(url)
        .then((response) => {
            console.log(response.data);
            setRowData(response.data.timesheetreports);
            setReportof(response.data.reportof);
            setYear(response.data.year);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    useEffect(() => {
        fetchItems();
        
    }, []);
    return (
        <Container fluid>
            <Breadcrumb className='mt-2'>
                <Breadcrumb.Item as={Link} to="/Admindashboard">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active>  Timesheets </Breadcrumb.Item>
            </Breadcrumb>
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6> Timesheets {(month == current || month == previous) ? month : months[month]+" "+current }</h6>
                </Col>
                <Col sm={4} className='pb-2'></Col>
                <Col sm={4} className="d-flex justify-content-end">
                    <Button variant="primary" className='rounded-0 text-white' style={{backgroundColor:'#2F9DCC'}}>Download</Button>
                </Col>
                <div className="ag-theme-quartz" // applying the grid theme
                style={{ height: 550 }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}                       
                    />
                </div>
           </Row>
        </Container>

    );
}

export default TimesheetsByYear
import React from 'react'
import {Routes,Route,Navigate} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../Assets/EmploymentExchange_Logo.svg';
import Form from 'react-bootstrap/Form';
import Networking from '../Assets/Networking.svg';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import support from '../Assets/Support_ico.svg';
import Authuser from '../Authuser';
import Dashboard from '../Pages/Dashboard';
import AllEmployees from '../Pages/AllEmployees';
import NewEmployeeDocumentation from '../Pages/NewEmployeeDocumentation';
import ViewPerformanceReview from '../Pages/ViewPerformanceReview';
import Timesheets from '../Pages/Timesheets';
import Documents from '../Pages/Documents';
import Events from '../Pages/Events';
import Holidays from '../Pages/Holidays';
import PayrollCalendar from '../Pages/PayrollCalendar';
import PaycheckLoginInfo from '../Pages/PaycheckLoginInfo';
import H1BDocumentation from '../Pages/H1BDocumentation';
import ReferralPolicy from '../Pages/ReferralPolicy';
import AllRequests from '../Pages/AllRequests';
import TimesheetsByYear from '../Pages/TimesheetsByYear';
import Feedback from '../Pages/Feedback';
import SupportCenter from '../Pages/SupportCenter';
import CreateUser from '../Pages/CreateUser';
import ManageUsers from '../Pages/ManageUsers';
import Changepassword from '../Pages/Changepassword';
function Homenav() {
  const {token,logout,getUser} = Authuser();
  let userdetails = getUser();
  const logoutUser = () =>{
    if(token !== undefined){
      logout();
    }
  }
  const isAuthenticated = (userdetails !== '') ? true : false;
  return (
    <>
      <nav class="navbar navbar-expand-lg" style={{backgroundColor:"#ffffff"}}>
        <div class="container-fluid">
        <Navbar.Brand  as={Link} to="/"><img src={logo} alt='Employment Exchange' className='img-fluid logo' style={{width:320}} /></Navbar.Brand>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <ul class="navbar-nav ms-md-auto gap-2 list-group-horizontal">
              
            
              <li class="nav-item dropdown rounded ">
              <Nav.Link  class="nav-link dropdown-toggle " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" > <i class="bi bi-grid-3x3-gap-fill" style={{fontSize:'14px'}}></i> <span style={{fontSize:'14px'}}>Services</span></Nav.Link >
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                
              <Dropdown.Item as={Link} to="/">Dashboard</Dropdown.Item>
              <Dropdown.Item as={Link} to="/AllEmployees">Employees</Dropdown.Item>
              <Dropdown.Item as={Link} to="/NewEmployeeDocumentation">New Employee Documentation</Dropdown.Item>
              <Dropdown.Item as={Link} to="/ViewPerformanceReview">Performance Review</Dropdown.Item>
              <Dropdown.Item as={Link} to="/Timesheets">Timesheets</Dropdown.Item>
              <Dropdown.Item as={Link} to="/Documents">Documents </Dropdown.Item>
              <Dropdown.Item as={Link} to="/Events">Events  </Dropdown.Item>
              <Dropdown.Item as={Link} to="/Holidays">Holidays  </Dropdown.Item>
              <Dropdown.Item as={Link} to="/PayrollCalendar">Payroll Calendar </Dropdown.Item>
              <Dropdown.Item as={Link} to="/PaychexLoginInfo">Paychex Login Info  </Dropdown.Item>
              <Dropdown.Item as={Link} to="/H1bDocumentationts">H1b Documentation </Dropdown.Item>
              <Dropdown.Item as={Link} to="/EmployeeReferralPolicy">Employee Referral Policy</Dropdown.Item>
              <Dropdown.Item as={Link} to="/AllRequests ">All Requests       </Dropdown.Item>

                </ul>
              </li>
                    
            </ul>
          <div class="collapse navbar-collapse" id="navbarNav">
        
          <Form className="me-auto my-2 my-lg-0">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2 search rounded-0"
                    aria-label="Search"
                  />
                </Form>
            <ul class="navbar-nav ms-md-auto gap-2 list-group-horizontal">
              
              <li class="nav-item rounded">
              <Nav.Link as={Link} to="/Connections" className='text-center roboto-light'><img src={Networking} alt='Networking' className='img-fluid'/> <br/>My Network</Nav.Link>
              </li>
              <li class="nav-item dropdown rounded mt-2">
              <Nav.Link  class="nav-link dropdown-toggle " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <img src={support} alt='Employment Exchange' className='img-fluid' /></Nav.Link >
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <Dropdown.Item as={Link} to="/SupportCenter">Support Center</Dropdown.Item>
                <Dropdown.Item as={Link} to="/Feedback">Feedback</Dropdown.Item>
                <Dropdown.Item as={Link} to="/Askmeanything">Ask me anything</Dropdown.Item>
                </ul>
              </li>
              <li class="nav-item rounded">
              <ul class="notification-drop">
                <li class="item">
                <Nav.Link as={Link} to="/Messages"> <i class="bi bi-chat-left-fill"></i>
              <span class="btn__badge pulse-button ">4</span> </Nav.Link>
                </li>
        </ul>           
              </li>

              <li class="nav-item rounded">
              <ul class="notification-drop">
                <li class="item">
                <Nav.Link > <i class="bi bi-bell-fill"></i>
              <span class="btn__badge pulse-button ">4</span>  </Nav.Link>   
              <ul className='shadow'>
              <li>David Lee <br/>
                <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                </li>
                <li>David Lee <br/>
                <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                </li>
                <li>David Lee <br/>
                <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                </li>
                <li>David Lee <br/>
                <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                </li>
                <li>David Lee <br/>
                <span className='roboto-light'>Hey, I just read your message. How are you?</span>
                </li>
                <li>
                <span className='roboto-light text-center'> <Nav.Link as={Link} to="/Notifications">View All</Nav.Link></span>
                </li>
            </ul>
          </li>
        </ul>
              </li>
              <li class="nav-item dropdown  pt-2 ml-2" style={{borderLeft:'1px solid #D5D5D5'}}>
              <Nav.Link class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-caret-down-fill"></i></Nav.Link>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                <Dropdown.Item><span className='fw-bold'>{userdetails?.firstname} {userdetails?.lastname}</span></Dropdown.Item>
                <Dropdown.Item as={Link} to="/ManageUsers">Manage Users</Dropdown.Item>
                <Dropdown.Item as={Link} to="/CreateUser">Create User</Dropdown.Item>
                <Dropdown.Item as={Link} to="/Changepassword">Change Password  </Dropdown.Item>
                  <hr></hr>
                    <Dropdown.Item onClick={logoutUser}>Logout</Dropdown.Item>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Routes>
        <Route path="/" exact element={<Dashboard />} />
        <Route path="/Feedback" exact element={ isAuthenticated ? <Feedback />  : <Navigate to="/" replace /> }  />
        <Route path="/SupportCenter" exact element={ isAuthenticated ? <SupportCenter/> : <Navigate to="/" replace /> } />
        <Route path="/AllEmployees" exact element={<AllEmployees />} />
        <Route path="/NewEmployeeDocumentation" exact element={<NewEmployeeDocumentation />} />
        <Route path="/ViewPerformanceReview" exact element={<ViewPerformanceReview />}/>
        <Route path="/Timesheets" exact element={<Timesheets />}/>
        <Route path="/Documents" exact element={<Documents />}/>
        <Route path="/Events" exact element={<Events />}/>
        <Route path="/Holidays" exact element={<Holidays />}/>
        <Route path="/PayrollCalendar" exact element={<PayrollCalendar />}/>
        <Route path="/PaychexLoginInfo" exact element={<PaycheckLoginInfo />}/>
        <Route path="/H1bDocumentationts" exact element={<H1BDocumentation />}/>
        <Route path="/EmployeeReferralPolicy" exact element={<ReferralPolicy />}/>
        <Route path="/AllRequests" exact element={<AllRequests />}/>
        <Route path="/ManageUsers" exact element={<ManageUsers />} />
        <Route path="/CreateUser" exact element={<CreateUser />} />
        <Route path="/Changepassword" exact element={<Changepassword />} />
        <Route path="/Timesheetsbyyear/:month" exact element={ isAuthenticated ? <TimesheetsByYear /> : <Navigate to="/" replace /> } />
      </Routes>
    </>
  )
}

export default Homenav
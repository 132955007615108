import React,{ useState,useEffect,useMemo } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import pdf from "../Components/Pdf";
import ViewDownloadDeleteRenderer from "../Components/ViewDownloadDeleteRenderer";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Authuser from '../Authuser';
import Spinner from 'react-bootstrap/Spinner';

function NewEmployeeDocumentation(){
    const [smShow, setSmShow] = useState(false);
    const { http, getUser } = Authuser();
    let userdetails = getUser();
    const [values,setValues]=useState({title:'',document:''});
    const [errors, setErrors] = useState({title: '',document: '',message:'' });
    const [message,setMessage]= useState();
    const [newempdocument, setNewempdocument] = useState([]);
    const [file, setFile] = useState(false);
    const [modalshow, setModalshow] = useState(false);
    const [show, setShow] = useState(false);
    const[loader,setLoader]=useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setMessage('');   
    }
    const handleModalShow = (filename) =>{
        setModalshow(true);
        setFile(filename);
    }
    // Row Data: The data to be displayed.
    
    const [rowData, setRowData] = useState([]);
    // Column Definitions: Defines the columns to be displayed.
    function fetchItems(){
      http.get(`/hr/empnewdocuments/${userdetails.email}`)
        .then((response) => {
          //console.log(response.data);
          setRowData(response.data.newempdocuments);
        })
        .catch(function (error) {
          // handle error
          console.log(error.response.data.error);
        });
  }
  useEffect(() => {
      fetchItems();
  }, []);
  const refreshData = () =>{
    fetchItems();
  }
    const [colDefs, setColDefs] = useState([
      { headerName:'Title',field: "title"}, 
      { headerName: "Attachment",field:"essdocument",
          cellRenderer: pdf,
            },    
      { field: "",
          cellRenderer: ViewDownloadDeleteRenderer,
          cellRendererParams: {
              folderName: 'empselfdocs', // Pass folder name or other data as custom params
              refreshData:refreshData
          },
          cellStyle: { overflow: "visible", zIndex: "auto" },// Set this line
          resizable: true,
          suppressMenu: true,
          minWidth: 100,
          width: 80

      },    
      { headerName: "SubmittedDate",field:"created_at" },
      
    ]);
    // ...
    const defaultColDef = useMemo(() => {
        return {
            flex: 1, 
            filter: true,
          
            
        }
    });
     
    
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Validate email
        if (!values.title.trim()) {
            newErrors.title = 'Title is required';
            isValid = false;
        } else {
            newErrors.title = '';
        }

        // Validate password
        if (!values.document.trim()) {
            newErrors.document = 'Document is required';
            isValid = false;
        } else {
            newErrors.password = '';
        }

        setErrors(newErrors);
        setMessage('');
        return isValid;
    };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let title = values.title;
        let document = e.target.document.files[0];
        const newErrors = { ...errors };
        
        if (validateForm()) {
          setLoader(true)
          http.post(`hr/addnewdocument/${userdetails.email}`,{title:title,document:document,userid:userdetails.id}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              setValues({
                title: '',
                document: '',
                message: '',
              });
              e.target.reset();
              fetchItems();
              setLoader(false);
              setMessage('Document added successfully!');
              
              //fetchItems();
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.title = error.response.data?.error?.title;
                newErrors.document = error.response.data?.error?.document;
                setErrors(newErrors);
            }
            if(error.response.status==413){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
   return (
    <Container fluid>
      <Breadcrumb className='mt-2'>
        <Breadcrumb.Item as={Link} to="/">Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item active> New Employee Documentation</Breadcrumb.Item>               
      </Breadcrumb>
      <Row className='m-1'>
          <Col sm={4} className='d-flex'>
              <h6>New Employee Documentation</h6>
          </Col>
          <Col sm={4} className='pb-2'>`</Col>
          <Col sm={4} className="d-flex justify-content-end">
          <Button variant="" onClick={() => setSmShow(true)} className='rounded-0 text-white' style={{backgroundColor:'#2F9DCC'}}>Add New Document</Button>
          </Col>
          <div className="ag-theme-quartz" // applying the grid theme
          style={{ height: 550 }} // the grid will fill the size of the parent container
          >
              <AgGridReact
                  rowData={rowData}
                  columnDefs={colDefs}
                  pagination={true}
                  paginationPageSize={10}
                  paginationPageSizeSelector={[10, 20]}
                  defaultColDef={defaultColDef}
                  
              />
              </div>
        </Row>
        <Modal size="md" show={smShow} onHide={() => setSmShow(false)} aria-labelledby="example-modal-sizes-title-sm">
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm" className='fw-bold'>
              Add Document
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form method="post" onSubmit={handleSubmit}>             
              <Form.Group className="mb-3" controlId="formBasicEmail">
                  <label>Document Name *</label>
                  <Form.Control type="text" name="title" onChange={handleChange} placeholder="Enter Document Name" />
                  <div style={{ display: "block" }} className="invalid-feedback small">{errors.title}</div>
              </Form.Group>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Upload Document</Form.Label>
                <Form.Control type="file" name="document" onChange={handleChange} />
                <div style={{ display: "block" }} className="invalid-feedback small">{errors.document}</div>
                <div style={{ display: "block" }} className="invalid-feedback small">{errors.message}</div>
                <div style={{display:'block', color: "#1cc88a" }} className="alert-success success small">{message}</div>
              </Form.Group>    
              <Col></Col>
              <div className='text-center'>
                {loader === true &&
                    <Spinner animation="border" className='mx-auto' role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                } 
                <Button variant="primary" type="submit" onClick={validateForm} style={{backgroundColor:'#2F9DCC'}} className='text-white ml-2'>Submit</Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal> 
    </Container>
    );
}

export default  NewEmployeeDocumentation
import React,{useState,useEffect} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { HttpStatusCode } from 'axios';
import Authuser from '../Authuser';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: '',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];


export const Quarterly = {
  labels: [
    ["Testing"],
    ["Development"],
    ["Marketing"],
    ["Support"],

  ],
  datasets: [
    {
      label: 'Count',
      data: [256, 100, 35, 60],
      backgroundColor: [
        'rgba(48, 192, 205)',
        'rgba(39, 69, 104)',
        'rgba(248, 180, 21)',
        'rgba(103, 145, 36)',

      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(54, 162, 235, 1)',

      ],
      borderWidth: 1,
    },
  ],
};



export const Monthly = {
  labels: [
    ["JAN"],
    ["FEB"],
    ["MAR"],
    ["APR"],
    ["MAY"],
    ["JUN"],
    ["JUL"],
    ["AUG"],
    ["SEP"],
    ["OCT"],
    ["NOV"],
    ["DEC"],
  ],
  datasets: [
    {
      label: 'Employees',
      data: '',
      backgroundColor: 'rgb(126, 165, 63)',
    },

  ],
};



function Dashboard(){
  const{http,getUser}=Authuser();
  let userdetails = getUser();
  const[empcount,setEmpcount]=useState();
  const[malecount,setMalecount]=useState();
  const[femalecount,setFemalecount]=useState();
  const[newcount,setNewcount]=useState(0);
  useEffect(() =>{
    http.get(`hr/dashboard/${userdetails.email}`)
    .then((response) => {
        //console.log(response.data);
        setEmpcount(response.data.employeecount);
        setMalecount(response.data.malecount)
        setFemalecount(response.data.femalecount)
    })
    .catch(function (error) {
    // handle error
    console.log(error.response.data.error);
    });
  },[])
  return (
    <Container fluid>
      <Container className='mt-3'>   
      <Row>
        <Col><h6 className='fw-bold pt-2'>Home</h6></Col>
        <Col className="d-flex justify-content-end">
        </Col>
      </Row>
        <Row className='mt-3'>
          <Col sm={12} md={12} lg={4}>         
          <Card className='rounded-0 shadow-sm' style={{minHeight:'455px'}}>
      <Card.Header className='card-title'>Employees by Country</Card.Header>
      <Card.Body>
      <Bar options={options} data={Monthly} />
        </Card.Body>
    </Card>
          </Col>
          <Col sm={12} md={12} lg={4}>
          <Card className='rounded-0 shadow-sm' style={{minHeight:'455px'}}>
      <Card.Header className='card-title'>Employees by Role</Card.Header>
      <Card.Body>
      <Pie data={Quarterly} />
        </Card.Body>
    </Card>
          </Col>
          <Col sm={12} md={12} lg={4}>
          <Card className='rounded-0 shadow-sm' style={{minHeight:'455px'}}>
      <Card.Header className='card-title'>Employees </Card.Header>
      <Card.Body>
      <Card.Text>
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >  <Card.Link as={Link} ><div className="ms-2 me-auto">
          <div className="fw-bold card-text-primary">Total Employees  <span>{empcount}</span></div>
          
        </div></Card.Link>
        </ListGroup.Item>
        <hr></hr>

        <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >  <Card.Link as={Link} ><div className="ms-2 me-auto">
          <div className="fw-bold card-text-primary">New Employees <span>{newcount}</span></div>
          
        </div></Card.Link>
        </ListGroup.Item>
        <hr></hr>
        <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      > <Card.Link as={Link} ><div className="ms-2 me-auto">
          <div className="fw-bold card-text-primary">Male <span>{malecount}</span></div>
          
        </div>
        </Card.Link>
        </ListGroup.Item>
        <hr></hr>
        <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      > <Card.Link as={Link} ><div className="ms-2 me-auto">
          <div className="fw-bold card-text-primary">Female <span>{femalecount}</span></div>
        </div>
        </Card.Link>
        </ListGroup.Item>
        <hr></hr>      
        </Card.Text>
        </Card.Body>
        </Card>
        </Col>
        </Row>
       <Row className='mt-4'>
    
        
          <Col sm={12} md={12} lg={4}>
         
         <Card className='rounded-0 shadow-sm mt-4'>
         <Card.Header className='card-title'>
      <Row>
        <Col>
        <div className=' d-flex justify-content-start'>Upcoming Events </div>  
        </Col>
        <Col>
       
        </Col>
      </Row>
     </Card.Header>
     <Card.Body>
     <Card.Text>
     <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     >  <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Seminars and Conferences</div>
         <span className='text-muted'>Monday, May,10, 2021 7 PM - 11PM IST</span>
       </div></Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Product Launch Events</div>
         <span className='text-muted'>Sunday, May,09, 2021 7 PM - 11PM IST</span>
       </div>
       </Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Team-Building Events.</div>
         <span className='text-muted'>Sunday, May,09, 2021 7 PM - 11PM IST</span>
       </div>
       </Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Executive Retreats and IncentivePrograms</div>
         <span className='text-muted'>Sunday, May,09, 2021 7 PM - 11PM IST</span>
       </div>
       </Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Executive Retreats and IncentivePrograms</div>
         <span className='text-muted'>Sunday, May,09, 2021 7 PM - 11PM IST</span>
       </div>
       </Card.Link>
       </ListGroup.Item>
       </Card.Text>
       </Card.Body>
   </Card>
         </Col>
     <Col sm={12} md={12} lg={4}>
     <Card className='rounded-0 shadow-sm mt-4'>
     <Card.Header className='card-title'>
      <Row>
        <Col>
        <div className=' d-flex justify-content-start'>Holidays   </div>  
        </Col>
        <Col>
        
        </Col>
      </Row>
      
    
      </Card.Header>
     <Card.Body>
     <Card.Text>
     <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     >  <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">New year's Day</div>
         <span className='text-muted'>Jan 01, 2024</span>
       </div></Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Memorial Day</div>
         <span className='text-muted'>May 29,2024</span>
       </div>
       </Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Independence Day</div>
         <span className='text-muted'>Jul 04, 2024</span>
       </div>
       </Card.Link>
       </ListGroup.Item> 
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Labor Day</div>
         <span className='text-muted'>May 29, 2024</span>
       </div>
       </Card.Link>
       </ListGroup.Item> 
       <hr></hr> 
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Veterans Day  </div>
         <span className='text-muted'>Nov 11, 2024</span>
       </div>
       </Card.Link>
       </ListGroup.Item> 
       </Card.Text>
       </Card.Body>
   </Card>
         </Col>
         <Col sm={12} md={12} lg={4}>
     <Card className='rounded-0 shadow-sm mt-4'>
     <Card.Header className='card-title'>
      <Row>
        <Col>
        <div className=' d-flex justify-content-start'>Timesheets   </div>  
        </Col>
        <Col>
        
        </Col>
      </Row>
      
    
      </Card.Header>
      
     <Card.Body>
     <Card.Text>
     <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     >  <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">View Timesheets</div>
         <span className='text-muted'>View and manage all employees timesheets.</span>
       </div></Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">Invoices</div>
         <span className='text-muted'> View and manage all invoices. </span>
       </div>
       </Card.Link>
       </ListGroup.Item>
       <hr></hr>
       <ListGroup.Item
       as="li"
       className="d-flex justify-content-between align-items-start"
     > <Card.Link href="#"><div className="ms-2 me-auto">
         <div className="fw-bold card-text-primary">E-Verify</div>
         <span className='text-muted'>  </span>
       </div>
       </Card.Link>
       </ListGroup.Item>  
       </Card.Text>
       </Card.Body>
   </Card>
         </Col>
       </Row>
       </Container>
    </Container>
  );
}

export default Dashboard
import React,{ useState,useMemo,useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Pdf from "../Components/Pdf";
import ViewDownload from "../Components/ViewDownload";
import Button from 'react-bootstrap/Button';
import Authuser from '../Authuser';
import Spinner from 'react-bootstrap/Spinner';
function ViewPerformanceReview(){
    const{http,getUser}=Authuser();
    let userdetails = getUser();
    const[loader,setLoader]=useState(false);
    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState([]);
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        { headerName:"EmployeeName",field: "firstname",
            cellRenderer:(params) =>{
                return(
                    <span>{params.data.firstname} {params.data.lastname}</span>
                )
            }
        }, 
        
        { headerName:"Q1",field: "Q1",
            cellRenderer: Pdf,
            
        }, 

        { field: "",
            cellRenderer: ViewDownload,
            cellStyle: { overflow: "visible", zIndex: "auto" },// Set this line
            resizable: true,
            suppressMenu: true,
            minWidth: 100,
            width: 80,
            cellRendererParams: {
                folderName: 'empselfperformance' // Pass folder name or other data as custom params
                ,columnname: 'Q1'
            }

        },
        { headerName:"Q2",field: "Q2",
        cellRenderer: Pdf,
        }, 

        { field: "",
            cellRenderer: ViewDownload,
            cellStyle: { overflow: "visible", zIndex: "auto" },// Set this line
            resizable: true,
            suppressMenu: true,
            minWidth: 100,
            width: 80,
            cellRendererParams: {
                folderName: 'empselfperformance' // Pass folder name or other data as custom params
                ,columnname: 'Q2'
            }
        },
        { headerName:"Q3",field: "Q3",
            cellRenderer: Pdf,
        }, 

        { field: "",
            cellRenderer: ViewDownload,
            cellStyle: { overflow: "visible", zIndex: "auto" },// Set this line
            resizable: true,
            suppressMenu: true,
            minWidth: 100,
            width: 80,
            cellRendererParams: {
                folderName: 'empselfperformance' // Pass folder name or other data as custom params
                ,columnname: 'Q3'
            }

        },
        { headerName:"Q4",field: "Q4",
            cellRenderer: Pdf,
        }, 

        { field: "",
            cellRenderer: ViewDownload,
            cellStyle: { overflow: "visible", zIndex: "auto" },// Set this line
            resizable: true,
            suppressMenu: true,
            minWidth: 100,
            width: 80,
            cellRendererParams: {
                folderName: 'empselfperformance' // Pass folder name or other data as custom params
                ,columnname: 'Q4'
            }
        },
        { field: "year", },  
    ]);   
    // ...
    const defaultColDef = useMemo(() => {
        return {
            flex: 1, 
            filter: true,
        
            
        }
    });
    function fetchItems(){        
        http.get(`/hr/performancereview/${userdetails.email}`)
        .then((response) => {
            //console.log(response.data);
            setRowData(response.data.performancereviews);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    useEffect(() => {
        fetchItems();
    },[]);
    const handleDownload = async() =>{
        setLoader(true)
        http.get(`/hr/downloadPerformance`,{responseType: 'blob',})
        .then((response) => {
            //console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url; //''
            link.setAttribute('download', 'Performace.zip');
            document.body.appendChild(link);
            link.click();
            setLoader(false)
            document.body.removeChild(link);

        })
        .catch(function (error) {
            // handle error
            setLoader(false)
            console.log(error.response.data.error);
        });
    }
    return (
        <Container fluid>
             <Breadcrumb className='mt-2'>
                <Breadcrumb.Item as={Link} to="/">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active> Performance Review </Breadcrumb.Item>
                
               
            </Breadcrumb>
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6> Performance Review</h6>
                </Col>
                <Col sm={4} className='pb-2'>
                {loader === true &&
                    <Spinner animation="border" className='mx-auto' role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                } 
                </Col>
                <Col sm={4} className="d-flex justify-content-end">
                    <Button variant=""className='rounded-0 text-white' onClick={handleDownload} style={{backgroundColor:'#2F9DCC'}}>Download</Button>
                </Col>

                <div className="ag-theme-quartz" // applying the grid theme
                style={{ height: 550 }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}
                       
                    />
                </div>
           </Row>
        </Container>

    );
}

export default ViewPerformanceReview
import React,{useState,useEffect,useMemo} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Authuser from '../Authuser';
function Events(){
    const [smShow, setSmShow] = useState(false);
    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState([]);
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([   
      { headerName:"Event Name" ,field: "eventname"},    
      { headerName:"Date and Time" ,field: "DateAndTime",
        cellRenderer: (params) => {
          return (
            <span>{params.data.eventdate} {params.data.eventtime}</span>
          );
        }
      },       
    ]);   
    // ...
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            filter: true,
        }
    });
    const [values,setValues]=useState({eventname:'',eventdate:'',eventtime:''});
    const [errors, setErrors] = useState({eventname:'',eventdate:'',eventtime:''});
    const [message,setMessage]= useState();
    const { http, getUser } = Authuser();
    let userdetails = getUser();
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Subject
        if (!values.eventname.trim()) {
            newErrors.eventname = 'Event name is required';
            isValid = false;
        } else {
            newErrors.eventname = '';
        }
        // eventdate
        if (!values.eventdate.trim()) {
            newErrors.eventdate = 'Event date is required';
            isValid = false;
        } else {
            newErrors.eventdate = '';
        }
        // File Upload
        if (!values.eventtime.trim()) {
            newErrors.eventtime = 'Event time is required';
            isValid = false;
        } else {
            newErrors.eventtime = '';
        }
        setErrors(newErrors);
        setMessage('');
        return isValid;
    };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let eventname = values.eventname;
        let eventdate = values.eventdate;
        let eventtime = values.eventtime;
        let message = '';
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`hr/addevent/${userdetails.email}`,{eventname:eventname,eventdate:eventdate,eventtime:eventtime,userid:userdetails.id}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              setValues({
                eventname: '',
                eventdate: '',
                eventtime: '',
              });
              e.target.reset();
              setMessage('Event added successfully!');
              fetchItems();
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.eventname = error.response.data?.error?.eventname;
                newErrors.eventdate = error.response.data?.error?.eventdate;
                newErrors.eventtime = error.response.data?.error?.eventtime;
                setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
    //
    const fetchItems = async () => {
        http.get(`hr/events/${userdetails.email}`)
        .then((response) => {
        //console.log(response.data);
        setRowData(response.data.events);
        })
        .catch(function (error) {
        // handle error
        console.log(error.response.data.error);
        });
      };
    useEffect(() => {
        fetchItems();
    }, []);
   return (
        <Container fluid>
             <Breadcrumb className='mt-2'>
                <Breadcrumb.Item as={Link} to="/Dashboard">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active> Events</Breadcrumb.Item>
               
            </Breadcrumb>
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6>  Events</h6>
                </Col>
                <Col sm={4} className='pb-2'>
                </Col>
                <Col sm={4} className="d-flex justify-content-end">
                <Button variant="primary" className='rounded-0 ml-2 shadow-sm' onClick={() => setSmShow(true)}><i class="bi bi-plus"></i>Add Event</Button>
                </Col>
                <div className="ag-theme-quartz m-auto" // applying the grid theme
                style={{ height: 550, width:600, }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}
                       
                    />
                    </div>
           </Row>

           <Modal
        size="md"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" className='fw-bold'>
          Add Event
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form method="post" onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className='fw-bold'>Event Name *</Form.Label>
            <Form.Control type="text" name="eventname" placeholder="Event Name" onChange={handleChange}/>
            <div style={{ display: "block" }} className="invalid-feedback small">{errors.eventname}</div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className='fw-bold'>Event Date *</Form.Label>
            <Form.Control type="Date" name="eventdate" placeholder="Event NaDateme" onChange={handleChange}/>
            <div style={{ display: "block" }} className="invalid-feedback small">{errors.eventdate}</div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className='fw-bold'>Event Time *</Form.Label>
            <Form.Control type="Time" name="eventtime" placeholder="Event Time" onChange={handleChange}/>
            <div style={{ display: "block" }} className="invalid-feedback small">{errors.eventtime}</div>
          </Form.Group>
          <div style={{ display: "block" }} className="alert-success small">{message}</div>
          <div className='text-center'>
            <Button variant="primary" type="submit" onClick={validateForm} className='rounded-0'>Submit</Button>
          </div>
        </Form>
        </Modal.Body>
      </Modal>
 </Container>

    );
}

export default Events
import React,{useState,useEffect} from "react";
import {
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import Authuser from '../Authuser';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
function EditDeleteRenderer(props) {
  const{http,getUser} = Authuser();
  let userdetails = getUser();
  const{data,refreshData}=props;
  const [modalshow, setModalshow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedid, setSelectedid] = useState(null);
  const[message,setMessage]=useState();
  const[userdata,setUserdata]=useState();
  const [values,setValues]=useState({paydate:''});
  const [errors, setErrors] = useState({paydate:'',message:''});
  const handleModalShow = (paydata) =>{      
    setModalshow(true);
    setValues(paydata);
  }
  const handleModalClose = () => {
    setSelectedid(null);
    setModalshow(false);
  };

  const handleShowModal = (id) => {
    setSelectedid(id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedid(null);
    setShowModal(false);
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };
    // Subject
    if (!values.paydate.trim()) {
        newErrors.paydate = 'Paydate is required';
        isValid = false;
    } else {
        newErrors.paydate = '';
    }
    setErrors(newErrors);
    setMessage('');
    return isValid;
};
  function handleChange(e){
      setMessage('');
      setValues({...values,[e.target.name]:e.target.value});
  }
  const handleEdit = async(e) =>{
    e.preventDefault();
    let paydate = values.paydate;
    let payid = e.target.id.value;
    let message = '';
    const newErrors = { ...errors };
    
    if (validateForm()) {
      http.post(`/hr/editpayroll/${userdetails.email}`,{paydate:paydate,payid:payid})
      .then((response) => {
          //console.log(response); 
          refreshData();
          setMessage('User updated successfully!');
          handleModalClose();            
      })
      .catch(function (error) {
          // handle error
      });
    }
      
  }
  return (
    <>
      <DropdownButton  as={ButtonGroup} size="sm" autoClose="outside" variant="muted" style={{ maxHeight: "28px" }}
          title={<span><i class="bi bi-three-dots"></i></span>}>            
        <Dropdown.Item href="#" onClick={()=>handleModalShow(data)}>Edit</Dropdown.Item>
        {/* <Dropdown.Item href="#" >Delete</Dropdown.Item> */ }
      </DropdownButton>
      <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={modalshow} onHide={handleModalClose} centered>
          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>                
            <Form method="post" onSubmit={handleEdit}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>PayPeriod</Form.Label>
                <Form.Control type="text" placeholder="Pay Period" value={values.paymonth} name="paymonth" readOnly onChange={handleChange} />
                <input type="hidden" name="id" value={data.id} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                <Form.Label>PayDate</Form.Label>
                <Form.Control type="text" placeholder="Pay Date" value={values.paydate} name="paydate" onChange={handleChange} />
                <div style={{ display: "block" }} className="invalid-feedback small">{errors.paydate}</div>
                <div style={{display:'block', color: "#1cc88a" }} className="alert-success success small">{message}</div> 
              </Form.Group>
              <div className='text-center mt-2'>
                <Button variant="primary" type="submit" onClick={validateForm} className="rounded-0 text-white" style={{backgroundColor:'#2F9DCC'}}>Submit</Button>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditDeleteRenderer
import React,{useState,useEffect,useMemo} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Authuser from '../Authuser';
function CreateUser() {
  const{http,getUser}=Authuser();
  let userdetails = getUser();
  const [values,setValues]=useState({firstname:'',lastname:'',email:'',designation:'',phoneno:''});
  const [errors, setErrors] = useState({firstname:'',lastname:'',email:'',designation:'',phoneno:''});
  const [message,setMessage]= useState();
  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const alphaRegex = /^[A-Za-z]+$/;
    const numRegex = /^[0-9\b]+$/;
    const phoneNumberRegex = /^\d{10}$/;

    // First Name
    if (!values.firstname.trim()) {
        newErrors.firstname = 'First name is required';
        isValid = false;
    }else if(!alphaRegex.test(values.firstname)){
        newErrors.firstname='Only Characters allowed';
        isValid = false;
    }else if(values.firstname.length < 3){
      newErrors.firstname='First name should be atleast 3 characters';
      isValid = false;
    }else {
        newErrors.firstname = '';
    }
    // Last Name
    if (!values.lastname.trim()) {
        newErrors.lastname = 'Last name is required';
        isValid = false;
    } else if(!alphaRegex.test(values.lastname)){
        newErrors.lastname='Only Characters allowed';
        isValid = false;
    }else if(values.lastname.length < 3){
      newErrors.lastname='Last name should be atleast 3 characters';
      isValid = false;
    }else {
        newErrors.lastname = '';
    }
    // Designation
    if (!values.designation.trim()) {
        newErrors.designation = 'Designation is required';
        isValid = false;
    } else if(!alphaRegex.test(values.designation)){
        newErrors.designation='Only Characters allowed';
        isValid = false;
    }else {
        newErrors.designation = '';
    }
    // Email
    if (!values.email.trim()) {
        newErrors.email = 'Email is required';
        isValid = false;
    } else  if(!emailRegex.test(values.email)){
        newErrors.email = 'Email not valid';
        isValid = false;
    }else {
        newErrors.email = '';
    }
    // Phone number
    if (!values.phoneno.trim()) {
        newErrors.phoneno = 'Phone is required';
        isValid = false;
    } else if(!numRegex.test(values.phoneno)){
        newErrors.phoneno='Only numbers allowed';
        isValid = false;
    }else if(!phoneNumberRegex.test(values.phoneno)){
      newErrors.phoneno='Phone number should be 10 digits';
      isValid = false;
    }else {
        newErrors.phoneno = '';
    }
    setErrors(newErrors);
    setMessage('');
    return isValid;
};
function handleChange(e){
    setMessage('');
    setValues({...values,[e.target.name]:e.target.value});
}
const handleSubmit = async(e) =>{
    e.preventDefault();
    let firstname = values.firstname;
    let lastname = values.lastname;
    let email = values.email;
    let phoneno = values.phoneno;
    let designation = values.designation;
    let message = '';
    const newErrors = { ...errors };
    
    if (validateForm()) {
      http.post(`hr/createuser/${userdetails.email}`,{firstname:firstname,lastname:lastname,email:email,phonenumber:phoneno,designation:designation,userid:userdetails.id}).then((response)=>{
          
          setValues({
            firstname:'',
            lastname:'',
            email:'',
            phoneno:'',
            designation:'' 
          });
          e.target.reset();
          setMessage('User created successfully!');
          
      }).catch(function (error) {
        // handle error
        //console.log(error.response.data.error);
        if(error.response.status==403){
            newErrors.email = error.response.data?.error?.email;
            newErrors.phoneno = error.response.data?.error?.phonenumber;
            setErrors(newErrors);
        }
        if(error.response.status==401){
            newErrors.message = error.response.data?.error;
            setErrors(newErrors);
        }
        //setsubmitted(false)
      })
    } else {
      // Form is not valid, display error messages

    }
}
  return (
    <Container fluid>
      <Breadcrumb className='mt-2'>
          <Breadcrumb.Item as={Link} to="/Dashboard">Dashboard</Breadcrumb.Item>
        
          <Breadcrumb.Item active> Create User  </Breadcrumb.Item>
          
      </Breadcrumb>
      <Container className='mt-3'>

        <Container className='bg-white p-4' style={{ width: '28rem',marginBottom:'20rem' }}>             
          <Row>
            <h6 className='fw-bold'>Create User</h6>
            <Form method="post" onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" placeholder="Enter First name" name="firstname" onChange={handleChange} />
                <div style={{ display: "block" }} className="invalid-feedback small">{errors.firstname}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" placeholder="Enter Last name" name="lastname" onChange={handleChange} />
                <div style={{ display: "block" }} className="invalid-feedback small">{errors.lastname}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                <Form.Label>Designation</Form.Label>
                <Form.Control type="text" placeholder="Enter Designation" name="designation" onChange={handleChange} />
                <div style={{ display: "block" }} className="invalid-feedback small">{errors.designation}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                <Form.Label>Email</Form.Label>
                <Form.Control type="text" placeholder="Enter Email" name="email" onChange={handleChange} />
                <div style={{ display: "block" }} className="invalid-feedback small">{errors.email}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type="text" placeholder="Enter Phone Number" maxLength={10} name="phoneno" onChange={handleChange}/>
                <div style={{ display: "block" }} className="invalid-feedback small">{errors.phoneno}</div>
                <div style={{display:'block', color: "#1cc88a" }} className="alert-success small">{message}</div> 
              </Form.Group>
              <div className='text-center mt-2'>
                <Button variant="primary" type="submit" onClick={validateForm} className="rounded-0 text-white" style={{backgroundColor:'#2F9DCC'}}>Submit</Button>
              </div>
            </Form>
          </Row>
        </Container>
        
      </Container>
    </Container>
  )
}

export default CreateUser
import React,{useState} from 'react'
import {
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";
import Authuser from '../Authuser';
import Edituser from './Edituser';
import Modal from 'react-bootstrap/Modal';
function UserActions(props) {
  const {data} = props;
  const [modalshow, setModalshow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedid, setSelectedid] = useState(null);
  const[message,setMessage]=useState();
  const{http,getUser} = Authuser();
  let userdetails = getUser();
  const[userdata,setUserdata]=useState();
  const handleModalShow = (id) =>{
      
      setModalshow(true);
      setUserdata(id);
  }
  const handleShowModal = (id) => {
      setSelectedid(id);
      setShowModal(true);
  };

  const handleCloseModal = () => {
      setSelectedid(null);
      setShowModal(false);
  };
  const handleDelete = () =>{
      http.post(`/hr/deleteuser/${userdetails.email}`,{id:selectedid})
      .then((response) => {
          //console.log(response); 
          props.refreshData();
          setMessage('User deleted successfully!');
          handleCloseModal();            
      })
      .catch(function (error) {
          // handle error
          console.log(error.response.data.error);
      });
  }
  return (
    <>
      <DropdownButton  as={ButtonGroup} size="sm"  autoClose="outside" variant="muted" style={{ maxHeight: "28px" }} title={<span><i class="bi bi-three-dots"></i></span>}>      
        <Dropdown.Item href="#" ><i class="bi bi-check2-circle"></i> Active</Dropdown.Item>
        <Dropdown.Item href="#" onClick={()=>handleModalShow(data.id)}>Edit  </Dropdown.Item>
        <Dropdown.Item href="#" onClick={()=>handleShowModal(data.id)}>Delete  </Dropdown.Item>
      </DropdownButton>
      <Edituser show={modalshow} id={(modalshow === true && userdata !='') ? userdata : ''} close={() => { setModalshow(false); props.refreshData(); } }/>
      <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>                
              <div className="input_fields_wrap">
                  <div className="mb-2 col-md-12">
                      <p style={{ color: '#2D8BB4' }}><strong>Are you sure you want to delete ?</strong></p>
                      <div style={{ display: "block" }} className="alert-success success small">{message}</div>
                  </div>
              </div>
          </Modal.Body>
          <Modal.Footer>
              <div className="mb-2 col-md-12 text-center">
                  <button type="button" className="btn btn-secondary ml-2" onClick={handleCloseModal}>No</button> 
                  <button type="submit" onClick={handleDelete} className="btn btn-primary ml-2">Yes</button>
              </div>
          </Modal.Footer>
      </Modal>
    </>
  )
}

export default UserActions

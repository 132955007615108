import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';


function ReferralPolicy(){
   
    return (
        <Container fluid>
           
            <Breadcrumb className='mt-2'>
                <Breadcrumb.Item as={Link} to="/Dashboard">Dashboard</Breadcrumb.Item>
                 <Breadcrumb.Item active> Employee Referral Policy  </Breadcrumb.Item>
                
            </Breadcrumb>
            <Container className='mt-3'>

                               <Container className='bg-white p-4 border'>
             
                    <Row>
                        <h6 className='fw-bold text-center'>Employee Referral Policy</h6>
                  <hr></hr>
                  <p className='fw-bold'>Introduction</p>  
                   <p>“Transnational Software Services Inc” Employee Referral Program is a recruiting tool designed to encourage “Transnational Software Services Inc”employees to refer qualified candidates to fill open positions at “Transnational Software Services Inc”.
                   </p>  
                   <p className='fw-bold'>Eligibility</p>  
                   <p>Current employees of “Transnational Software Services Inc” are eligible to make referrals through this program. In case of multiple referrals, the first employee to submit the referral will be recognized as the referring employee. The candidate who is referred to the open job position should not be a current employee of the company.
                   </p> 
                   <p className='fw-bold'>Incentives Offered </p>  
                   <p>If the new employee, who was hired through employee referral program, stays with the company for at least 90_days or more, then the referring employee receives a special bonus in the amount of $1000. There is no limit to the number of referrals an employee can make or the number of referral bonuses an employee may receive.
                   </p> 
                   <p className='fw-bold'>Referral Procedure  </p>  
                   <p>Candidates can be referred to any open position by contacting the HR directly. If the candidate referred by the employee is offered and accepts the position, HR will contact the employee who made the referral directly.  Upon meeting the requirements the candidate must apply with the HR Department for the referral fees by providing the candidate name.
                   </p>    
                    </Row>
                </Container>
               <p className='d-flex justify-content-end'>Last updated on : May 16, 2022</p>
            </Container>
        </Container>
    );
}

export default ReferralPolicy